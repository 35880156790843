<template>
  <div>
    <MistralChat />
  </div>
</template>

<script>
import MistralChat from "./components/MistralChat.vue";

export default {
  name: "App",
  components: {
    MistralChat,
  },
};
</script>

<style>
</style>
